import React, { Component } from 'react';

class SectionImage extends Component {

    constructor(props) {
        super(props) 
    }

    render() {
        return (
            <div className="sectionimage" style={this._styles.container}>
                {this.props.content}
            </div>
        );
    }

    _styles = {
        container: {
            zIndex: '-100',
            background: 'url('+this.props.overrideImage +') center center no-repeat',
            WebkitBackgroundSize: 'cover !important',
            MozBackgroundSize: 'cover !important',
            OBackgroundSize: 'cover !important',
            backgroundSize: 'cover !important',
            objectFit: 'cover',
        }
      }

    /*
    this.style.fixedbackground {
        position: fixed;
        width: calc(100% - 120px);
        height: calc(100vh - 300px);
        top: 150px;
        bottom: 150px;
        left: 60px;
        right: 60px;
        z-index: -100;
        background: url('./assets/about_office1.jpeg')center center fixed no-repeat;
        -webkit-background-size: cover !important;
        -moz-background-size: cover !important;
        -o-background-size: cover !important;
        background-size: cover !important;
      }
      */
}

export default SectionImage