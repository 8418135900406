import React, { Component } from 'react';

import BackgroundOffice from '../assets/about_office1.jpeg'

class FixedCenteredBackground extends Component {

    constructor(props) {
        super(props) 
    }

    render() {
        return (
            <div style={this._styles.container}>
            </div>
        );
    }

    _styles = {
        container: {
            /*position: 'fixed',*/
            width: 'calc(100% - 120px)',
            height: 'calc(100vh - 300px)',
            top: '150px',
            bottom: '150px',
            left: '60px',
            right: '60px',
            zIndex: '-100',
            background: 'url('+BackgroundOffice +') center center fixed no-repeat',
            WebkitBackgroundSize: 'cover !important',
            MozBackgroundSize: 'cover !important',
            OBackgroundSize: 'cover !important',
            backgroundSize: 'cover !important',
        }
      }

    /*
    this.style.fixedbackground {
        position: fixed;
        width: calc(100% - 120px);
        height: calc(100vh - 300px);
        top: 150px;
        bottom: 150px;
        left: 60px;
        right: 60px;
        z-index: -100;
        background: url('./assets/about_office1.jpeg')center center fixed no-repeat;
        -webkit-background-size: cover !important;
        -moz-background-size: cover !important;
        -o-background-size: cover !important;
        background-size: cover !important;
      }
      */
}

export default FixedCenteredBackground