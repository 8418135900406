import React, { Component } from 'react';

import GameLinks from './GameLinks';

class GameDetails extends Component {

    constructor(props) {
        super(props) 
    }

    render() {
        return (
            <React.Fragment>
                {/* We are using the CSS classes from App.css, and since this is called from a component that imports it, it works */}
                <div className="contenido contenidoMobileBackground">
                    <div className="maintext">
                        {this.props.title ? this.props.title : 'Title'}
                    </div>
                    <div className="subtext">
                        {this.props.date ? this.props.date : 'year 2020'}
                    </div>
                    <div className="smalltext" style={{marginTop: '10px'}}>
                        {this.props.short ? this.props.short : 'SHORT DESCRIPTION OF THE GAME'}
                    </div>
                    <div className="subtext">
                        <GameLinks youTube={this.props.youTube}
                                appStore={this.props.appStore}
                                steam={this.props.steam}
                                nintendoSwitch={this.props.nintendoSwitch}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default GameDetails