import React, { Component } from 'react';
import './App.css';

import VideoFullscreen from './components/VideoFullscreen';
import GameDetails from './components/Games/GameDetails';

import FixedCenteredBackground from './components/FixedCenteredBackground'
import SectionImage from './components/SectionImage'

import ImageStarman from './assets/starman_image1.jpg'
import Image1sland from './assets/1sland_image1.png'
import ImageFlocks from './assets/flocks_image1.png'

import ImageAbout from './assets/about_office1.jpeg'

class App extends Component {

  constructor(props) {
    super(props)

    this._refStarman = React.createRef() 
    this._ref1sland = React.createRef() 
    this._refFlocks = React.createRef() 
    this._refAbout = React.createRef() 
    this._refContact = React.createRef()  
}

render() {
  return (
    <React.Fragment>
      {/* Overlay corner menus */}
      <div className="topleft">
          <div className="sectionmenu" onClick={() => this._refStarman.scrollIntoView()}>Starman</div>
          <div className="sectionmenu" onClick={() => this._ref1sland.scrollIntoView()}>1sland</div>
          <div className="sectionmenu" onClick={() => this._refFlocks.scrollIntoView()}>Flocks</div>
          <div className="sectionmenu" onClick={()=>window.open('https://getnuits.com', "_blank")}>Nuits</div>
      </div>
      <div className="topright">
          <div className="sectionmenu" onClick={() => this._refAbout.scrollIntoView()}>About</div>
      </div>

      <div className="bottomleft">
          <div className="sectionmenu" onClick={() => this._refContact.scrollIntoView()}>Contact</div>
      </div>
      <div className="bottomright">
          <div className="sectionmenu">©2016-{new Date().getFullYear()}</div>
      </div>

      

      {/* Top Main */}
      <div className="general_centrado">
      <VideoFullscreen/>
      {/*}
        <div className="contenido">
          <div className="maintext">
            nada
          </div>
        </div>
    */}
      </div>

      

      {/* Starman */}
      <div className="general_centrado" ref={(ref) => this._refStarman=ref}>
        <GameDetails title="starman"
                    date="November 2017"
                    short="A breathtaking architectural puzzler"
                    youTube="https://www.youtube.com/watch?v=V_s5j-Z7Kb8"
                    appStore="https://apps.apple.com/us/app/starman/id1157484554"
                    steam="https://store.steampowered.com/app/804350/Starman/"
                    nintendoSwitch="https://www.nintendo.com/games/detail/starman-switch/"/>
        <SectionImage overrideImage={ImageStarman}/>
      </div>

      {/* 1sland */}
      <div className="general_centrado" ref={(ref) => this._ref1sland=ref}>
        <SectionImage overrideImage={Image1sland}/>
        <GameDetails title="1sland"
                    date="2020"
                    short="A beautiful & relaxing sailing adventure"
                    youTube="https://www.youtube.com/watch?v=c5GE_vBoTSs"
                    appStore="https://apps.apple.com/us/app/1sland/id1492928510"/>
      </div>

      {/* Flocks */}
      <div className="general_centrado" ref={(ref) => this._refFlocks=ref}>
        <GameDetails title="Flocks"
                    date="2021"
                    short="A colorful playground to be solved"
                    youTube="https://www.youtube.com/watch?v=Pj9jd8wkDoY"
                    appStore="https://apps.apple.com/us/app/flocks/id1340904970"/>
        <SectionImage overrideImage={ImageFlocks}/>
      </div>

      {/* About */}
      <div className="general_centrado" ref={(ref) => this._refAbout=ref}>
        <div className="contenido">
          <div className="subtext">
          We are a digital company developing <span className="underline-yellow">game</span> experiences.
          Founded in 2016 by two architects, Sergio Abril and Jacobo Abril.
          Based in Valladolid, Spain.
           </div>
        </div>
      </div>

      {/* Contact */}
      <div className="general_centrado" ref={(ref) => this._refContact=ref}>
        <div className="contenido" style={{flexDirection: 'column'}}>
          <div className="maintext" style={{cursor: 'pointer'}} onClick={() => window.open('mailto:hola@nada.studio')}>
            HOLA @ NADA.STUDIO
          </div>
          <div className="subtext">
            sergio abril & jacobo abril
          </div>
          <div className="smalltext" style={{marginTop: '10px'}}>
            calle valle de aran 9
          </div>
          <div className="smalltext">
            (Office N2M17),
          </div>
          <div className="smalltext">
            Valladolid, 47010, Spain.
          </div>
        </div>
      </div>
      
    </React.Fragment>);
  }

}

export default App;
