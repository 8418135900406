import React, {Component} from 'react'
import oneVideoMp4 from '../assets/fullscreen.mp4';
//import oneVideoMask from '../images/video_mask.svg';

import '../styles/FadeAnimations.css'


class VideoFullscreen extends Component{
    constructor(props){
        super(props)
        this.state = {}
    }
    render(){
          return(  
            <div style={this.styles.parent}>
                <video id="background-video" style={this.styles.video} playsInline /*loop*/ muted autoPlay preload="none">
                  <source src={oneVideoMp4} type="video/mp4" />
                  {/*message to show when video is not supported: Your browser does not support the video tag.*/}
                </video>
                {/*<img src={oneVideoMask} style={{position: 'absolute', pointerEvents:'none', top:'0px', width: '100%',  maxHeight: '500px', display:'block', zIndex:1000}}/>*/}
                <div style={this.styles.overlayTitle}>
                    nada studio
                </div>
            </div>
        )
    }


    styles = {
        //Make video cover as an image:
        //https://stackoverflow.com/questions/10797632/simulate-background-sizecover-on-video-or-img
        parent: {
            position: 'relative',
            width:'100%',
            height: '100vh',
            overflow: 'hidden'
        },
        video : {
            height: '100%',
            width: '177.77777778vh',
            minWidth: '100%',
            minHeight: '56.25vw',

            position: 'absolute',
            left: '50%', /* % of surrounding element */
            top: '50%',
            transform: 'translate(-50%, -50%)' /* % of current element */
        },

        overlayTitle:
        {
            position: 'absolute',
            pointerEvents:'none',
            top:'0px',
            width: '100%', 
            height: '100vh',
            display:'block',
            zIndex:1000, display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            fontFamily: 'MyFutura, Avenir-Light, Helvetica, Arial',
            fontSize: '36px',
            color: '#000000',
            letterSpacing: '3.8px',
            textTransform: 'lowercase',

            WebkitAnimationName: 'fadeIn',
            WebkitAnimationDuration: '5s',
            animationName: 'fadeIn',
            animationDuration: '5s',
        }
    }
}

export default VideoFullscreen