import React, { Component } from 'react';


class GameLinks extends Component {

    constructor(props) {
        super(props) 
    }

    getLinks = () => {
        let linksToPrint = [];


        //YOUTUBE
        if(this.props.youTube !== undefined)
        {
            linksToPrint.push(<div key="yt" style={this._styles.link} onClick={()=>window.open(this.props.youTube, "_blank")}>YOUTUBE</div>)
        }

        //APPLE
        if(this.props.appStore !== undefined)
        {
            linksToPrint.push(<React.Fragment>&nbsp;·&nbsp;</React.Fragment>)
            linksToPrint.push(<div key="appstore" style={this._styles.link} onClick={()=>window.open(this.props.appStore, "_blank")}>APPSTORE</div>)
        }

        //STEAM
        if(this.props.steam !== undefined)
        {
            linksToPrint.push(<React.Fragment>&nbsp;·&nbsp;</React.Fragment>)
            linksToPrint.push(<div key="steam" style={this._styles.link} onClick={()=>window.open(this.props.steam, "_blank")}>STEAM</div>)
        }

        //SWITCH
        if(this.props.nintendoSwitch !== undefined)
        {
            linksToPrint.push(<React.Fragment>&nbsp;·&nbsp;</React.Fragment>)
            linksToPrint.push(<div key="switch" style={this._styles.link} onClick={()=>window.open(this.props.nintendoSwitch, "_blank")}>SWITCH</div>)
        }

        return (
            <React.Fragment>
            {linksToPrint}
            </React.Fragment>
        )
    }

    render() {
        return (
            <div style={this._styles.general}>
                {this.getLinks()}
            </div>
        );
    }

    _styles = {
        general:{
            marginTop: '20px',
            fontSize: '10px',
            display: 'flex',
            flexDirection: 'row'
        },
        link: {
            fontSize: '12px',
            cursor: 'pointer',
            color: '#666666'
        }
      }
}

export default GameLinks